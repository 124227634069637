<template>
  <b-container>
    <b-card>
      <div class="row justify-content-center align-items-center">
        <div class="col-xs-10 col-sm-10 col-md-6 col-lg-6">
          <div class="d-flex justify-content-center align-items-center">
            <feather-icon icon="CheckIcon" size="400" class="text-success" />
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <h1>Thank you !</h1>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <h3>Order submitted.</h3>
          </div>
          <router-link :to="{ name: 'dashboard' }">
            <div class="d-flex justify-content-center align-items-center">
              <b-button variant="gradient-primary" class="box-shadow-1 w-50">
                Back to Dashboard
              </b-button>
            </div>
          </router-link>
          <router-link :to="{ name: 'freight-forwarding-order-tracker' }">
            <div class="d-flex justify-content-center align-items-center mt-1">
              <b-button variant="gradient-primary" class="box-shadow-1 w-50">
                Go to Order Tracker
              </b-button>
            </div>
          </router-link>
          <router-link :to="{ name: 'freight-forwarding-create-delivery' }">
            <div class="d-flex justify-content-center align-items-center mt-1">
              <b-button variant="gradient-primary" class="box-shadow-1 w-50">
                Continue Ordering
              </b-button>
            </div>
          </router-link>
        </div>
      </div>
    </b-card>
  </b-container>
</template>

<script>
export default {
  components: {},

  data() {
    return {}
  },
}
</script>

<style lang="scss">
@import 'src/assets/scss/style.scss';
</style>
